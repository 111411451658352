import React from 'react';
import Image from "next/image";
import SectionTitle from "@/components/sectionTitle";
import lisa from '@/assets/img/Lisa van Rijzewijk.jpg'
import bodyFont from "@/components/bodyFont";

function Results() {
    return (
        <div className="bg-black flex flex-col items-center text-gold py-20 px-5">
            <div className="my-10">
                <SectionTitle>Over mij</SectionTitle>
            </div>
            <div className="flex flex-1 flex-col md:flex-row max-h-fit overflow-hidden max-w-3xl">
                <div className="w-full md:w-1/2 flex-grow">
                    <Image src={lisa} alt="Hairstyliste Lisa van Rijzewijk"/>
                </div>
                <div
                    className={`${bodyFont.className} md:w-1/2 md:ml-8 ml-0 pt-8 md:pt-0 text-copy md:flex-row`}>
                    <p className="text-xl font-thin">
                        Vroeger waren Barbie’s niet veilig in mijn buurt. Voor je het wist had ik ze voorzien van een
                        nieuwe look.
                        Deze interesse is nooit verdwenen, dus koos ik voor het kappersvak.
                        <br/>
                        <br/>
                        Met een diploma op zak startte ik als zzp-er ambulant kapster. Na 10 jaar in mijn Suzuki Swift
                        door Meijerijstad te hebben gescheurd en veel ervaring opgedaan te hebben was het tijd voor een
                        volgende stap. Een stap over de drempel van mijn eigen salon. Hier staat sinds 2020 een
                        relax-moment, passie en kwaliteit centraal voor de modebewuste vrouw én man. Om dit te blijven
                        leveren blijf ik leergierig, altijd kritisch, sociaal en een tikkeltje eigenwijs.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Results);
