import React, {useMemo} from 'react';
import Image from "next/image";
import haar1 from '@/assets/img/resultaten/HAAR1.jpg'
import haar2 from '@/assets/img/resultaten/HAAR2.jpg'
import haar3 from '@/assets/img/resultaten/HAAR3.jpg'
import haar4 from '@/assets/img/resultaten/HAAR4.jpg'
import haar5 from '@/assets/img/resultaten/HAAR5.jpg'
import haar6 from '@/assets/img/resultaten/HAAR6.jpg'
import haar7 from '@/assets/img/resultaten/HAAR7.jpg'
import haar8 from '@/assets/img/resultaten/HAAR8.jpg'
import haar9 from '@/assets/img/resultaten/HAAR9.jpg'
import Carousel from 'react-grid-carousel'


function ResultCarousel(props) {
    const imagesRandom = useMemo(() => {
        return [
            {
                src: haar1,
                alt: 'Resultaat 1'
            },
            {
                src: haar2,
                alt: 'Resultaat 2'
            },
            {
                src: haar3,
                alt: 'Resultaat 3'
            },
            {
                src: haar4,
                alt: 'Resultaat 4'
            },
            {
                src: haar5,
                alt: 'Resultaat 5'
            },
            {
                src: haar6,
                alt: 'Resultaat 6'
            },
            {
                src: haar7,
                alt: 'Resultaat 7'
            },
            {
                src: haar8,
                alt: 'Resultaat 8'
            },
            {
                src: haar9,
                alt: 'Resultaat 9'
            }
        ];
    }, []);

    return (
        <div className="max-w-3xl mt-10">
            <Carousel mobileBreakpoint={0} responsiveLayout={[
                {
                    breakpoint: 500, cols: 2,
                },
                {
                    breakpoint: 375, cols: 1,
                },
            ]} dotColorActive={'#a78b55'} cols={3} rows={1} gap={20} loop showDots={true}>
                {imagesRandom.map((image, index) => (
                    <Carousel.Item key={image.src}>
                        <div className="flex">
                            <Image alt={image.alt} className="object-contain w-full h-[300px] md:h-[500px]"
                                   src={image.src}/>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}

export default ResultCarousel;
