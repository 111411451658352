import React from 'react';
import titleFont from "@/components/titleFont";

function SectionTitle({children}) {
    return (
        <h1 className={`${titleFont.className} text-5xl md:text-7xl leading-normal mb-10`}>{children}</h1>
    );
}

export default SectionTitle;
