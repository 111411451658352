import localFont from 'next/font/local'

const bodyFont = localFont({
    src: [
        {
            path: './fonts/CeraPRO-Thin.ttf',
            weight: '100',
            style: 'thin',
        },
        {
            path: './fonts/CeraPRO-Medium.ttf',
            weight: '500',
            style: 'normal',
        },
        {
            path: './fonts/CeraPRO-Bold.ttf',
            weight: '700',
            style: 'normal',
        },
    ],
})

export default bodyFont;
