import React from 'react';
import SectionTitle from "@/components/sectionTitle";
import kleurStaal from '@/assets/img/werkwijze/KLEURBOEKGOED.jpg'
import killerLook from '@/assets/img/werkwijze/9 KNIP.jpg'
import kleuren from '@/assets/img/werkwijze/2 WASBAK.jpg'
import fohn from '@/assets/img/werkwijze/1 FÖHN.jpg'
import spotlights from '@/assets/img/werkwijze/6 SPOTLIGHTS.jpg'
import Image from "next/image";

const items = [
    {
        title: 'Een eerlijk advies',
        body: 'Iedere wens is anders, maar ieder haartype ook. Daarom is elke behandeling op en top maatwerk. Met een eerlijk advies en een duidelijk plan komen we tot het mooiste resultaat.',
        image: kleurStaal,
        imageAlt: 'Advies door middel van kleurstaal',
    },
    {
        title: 'Knippen',
        body: 'Gezichtsvorm, haarstructuur en de valling van het haar zijn belangrijke aspecten voor het perfecte model. Mijn speciale knip- en krultechnieken en barber skills haal ik graag uit de kast.',
        image: killerLook,
        imageAlt: 'Belangrijke knip- en krultechnieken'
    },
    {
        title: 'Kleuren',
        body: 'Kleuringen zijn de specialiteit van het huis! Waaronder balayages, highlighting en faceframing. Om de kwaliteit te behouden gebruik ik de beste producten. Van Olaplex tot Wella professional.',
        image: kleuren,
        imageAlt: 'Belangrijke kleur aspecten'
    },
    {
        title: 'Stylen',
        body: 'Van een quick fix tot aan glamour beach waves. En van een elegant bruidskapsel tot aan een ‘blown-away’ fohn cursus om je haar zelf te leren stylen alsof je net van de kapper komt. Style opties genoeg!',
        image: fohn,
        imageAlt: 'Styling opties'
    },{
        title: 'Service',
        body: 'Naast een relaxte sfeer, heb ik heerlijke cappuccino of thee en gratis parkeergelegenheid. Ook is er een speciaal verwen arrangement te boeken met een vriend of vriendin.',
        image: spotlights,
        imageAlt: 'Jij in de spotlight!'
    },
]

export function Button({title, link}) {
    return <a href={link}
              className="text-gold font-medium text-xl md:text-2xl border border-gold px-10 md:px-16 py-3 transition hover:bg-gold/20 ease-in-out">{title}</a>;
}

function Introduction() {
    return (
        <div className="bg-black flex flex-col justify-center items-center py-20 text-gold px-5">
            <div className="my-10">
                <SectionTitle>Werkwijze</SectionTitle>
            </div>
            <div className={`flex flex-col gap-8 max-w-3xl`}>
                {items.map((item, index) => (
                    <div key={item.title}
                         className={`flex flex-1 flex-col ${index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'} max-h-fit overflow-hidden`}>
                        <div className="w-full md:w-1/2 flex-grow">
                            <Image className="object-cover object-top h-full w-full aspect-video" src={item.image} alt={item.imageAlt}/>
                        </div>
                        <div
                            className={`md:w-1/2 px-10 py-14 text-copy ${index === 0 || index === 3 ? 'bg-gold' : `bg-black border border-gold ${index % 2 === 0 ? 'md:border-l-0' : 'md:border-r-0'} border-t-0 md:border-t`}`}>
                            <h2 className="font-bold text-xl">{item.title}</h2>
                            <p className="font-thin text-lg">{item.body}</p>
                        </div>
                    </div>
                ))}
            </div>
            <div className="mt-8 flex justify-center items-center">
                <Button title="Klik voor de prijslijst" link="prijslijst"/>
            </div>
        </div>
    );
}

export default React.memo(Introduction);
