import React from 'react';
import wella from '@/assets/img/partners/WELLA ZWART.png'
import olaplex from '@/assets/img/partners/OLAPLEX ZWART.png'
import dyson from '@/assets/img/partners/DYSON ZWART.png'
import mac from '@/assets/img/partners/MAC ZWART.png'
import Image from "next/image";

function Partners() {
    return (
        <div
            className={`grid grid-cols-1 md:grid-cols-4 py-36 md:py-44 bg-gold items-center gap-y-36 gap-16 md:gap-32 px-28`}>
            <div className={`flex-1`}>
                <Image loading="lazy" src={wella} alt="Wella partner"/>
            </div>
            <div className={`flex-1`}>
                <Image loading="lazy" src={olaplex} alt="Olaplex partner"/>
            </div>
            <div className={`flex-1`}>
                <Image loading="lazy" src={dyson} alt="Dyson partner"/>
            </div>
            <div className={`flex-1`}>
                <Image loading="lazy" src={mac} alt="Mac partner"/>
            </div>
        </div>
    );
}

export default React.memo(Partners);
