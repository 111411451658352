export default {
    instagramUrl: 'https://www.instagram.com/hairstyliste.lisa/',
    facebookUrl: 'https://www.facebook.com/Hairstylistelisa/',

    prices: [{
        "title": "Ladies basic (vanaf 12 jaar)",
        "features": ["wassen", "knippen", "drogen"],
        "price": 27
    }, {
        "title": "Ladies Deluxe",
        "features": ["wassen", "olaplex haarmasker", "knippen", "drogen"],
        "price": 31
    }, {
        "title": "Ladies Premium",
        "features": ["wassen", "olaplex haarmasker", "knippenstyling", "model föhnen/ krullen/stijltang"],
        "price": 39
    }, {
        "title": "Ladies Premium Olaplex boost",
        "features": ["wassen", "30 min Olaplex boost", "knippen styling", "model föhnen/ krullen/stijltang"],
        "price": 65
    }, {
        "title": "Ladies curl cutting",
        "features": ["wassen", "masker", "krulkniptechniek", "difuser"],
        "price": 39
    }, {
        "padding": true,
        "title": "Men basic (vanaf 12 jaar)",
        "features": ["wassen", "knippen", "styling"],
        "price": 24
    }, {
        "title": "Men premium",
        "features": ["wassen", "knippen", "reshade color", "styling"],
        "price": 36
    }, {
        "padding": true,
        "title": "Kleuringen",
        "features": ["Uitgroei kleuring"],
        "price": 43
    }, {
        "title": null,
        "features": ["Volledige kleuring"],
        "price": 57
    }, {
        "title": null, "features": ["Kwart folie kleuring"], "price": 45
    }, {
        "title": null,
        "features": ["Halve folie kleuring"],
        "price": 65
    }, {"title": null, "features": ["Volledige folie kleuring"], "price": 85}, {
        "title": null,
        "features": ["Ontkleuring + toning"],
        prefix: 'va.', price: 95
    }, {"title": null, "features": ["Olaplex toevoeging"], prefix: 'va.', price: 17}, {
        "title": null,
        "features": ["Toner"],
        "price": 25
    }, {
        "padding": true,
        "title": "Balayage True tone",
        "features": ["Oude balayage opfrissen met toners + Olaplex"],
        "price": 85
    }, {
        "title": "Balayage Deluxe",
        "features": ["Uitblonderen", "toners + Olaplex"],
        "price": 127
    }, {
        "title": "Balayage Premium",
        "features": ["Uitblonderen", "roots", "toners + Olaplex"],
        "price": 169
    }, {
        "padding": true,

        "title": "Extra",
        "features": ["Party hair"], "price": 35}, {
        "title": null,
        "features": ["Party hair & make-up"],
        "price": 55
    }, {"title": null, "features": ["Bruidkapsels"], hideEuro: true, "price": 'Op aanvraag'}]


}
