import Jumbotron from "@/sections/jumbotron";
import Introduction from "@/sections/introduction";
import Operation from "@/sections/operation";
import Partners from "@/sections/partners";
import Results from "@/sections/results";
import About from "@/sections/about";
import Footer from "@/sections/footer";
import Head from 'next/head'

export default function Home() {
    return (
        <main className="relative">
            <Head>
                <title>Hairstyliste Lisa | Kapsalon in Nijnsel en Meijerijstad</title>
            </Head>
            <Jumbotron/>
            <Introduction/>
            <Operation/>
            <Results/>
            <About/>
            <Partners/>
            <Footer/>
        </main>
    )
}
