import React, {useEffect, useState} from 'react';
import comma from '@/assets/img/KOMMA.png'
import Image from "next/image";
import SectionTitle from "@/components/sectionTitle";
import ResultCarousel from "@/components/resultCarousel";
import {Button} from "@/sections/operation";
import configData from "@/components/utils/configData";
import Carousel from "react-grid-carousel";
import {useWindowSize} from "rooks";

const reviews = [
    {
        name: 'Noor',
        review: 'Fijne en kundige kapster. Ze gaat mee met de trends en geeft goed en passend advies.'
    },
    {
        name: 'Odessa',
        review: 'Lisa voelt goed aan wat je wilt. Of het nou totaal iets nieuws is of gewoon even lekker me-time.'
    },

    {
        name: 'Cecile',
        review: 'Deskundig, super gezellig en heeft lekkere chocolaatjes. Altijd een feestje om weer te gaan :)'
    },
    {
        name: 'Sander',
        review: 'Een Topkapster! Omdat mijn haren wat dunner worden, adviseerde ze mij de juiste haarwax. Nu ben ik zelfverzekerder.'
    },
    {
        name: 'Dena',
        review: 'Prettig persoon. Gebruikt fijne producten en kan als de beste stylen. Loop altijd als een soort Beyonce naar buiten.'
    },
    {
        name: 'Nicky',
        review: 'Lisa is realistisch en geeft eerlijk advies. Geeft veel aandacht en daardoor altijd geweldig resultaat.'
    },
]

function Results() {
    const {innerWidth, innerHeight} = useWindowSize();

    const [width, setWidth] = useState('100%');
    useEffect(() => {
        setWidth(innerWidth - 36);
    }, [innerWidth])
    return (
        <div className="bg-primary flex flex-col items-center text-gold py-20">
            <div className="my-10">
                <SectionTitle>Results</SectionTitle>
            </div>
            <div className="flex flex-col items-center border-t border-b border-gold py-10 max-w-md">
                <div className="w-1/6">
                    <Image src={comma} alt="Klantreviews"/>
                </div>
                <div className="mt-10 w-full">
                    <Carousel
                        containerStyle={{
                            maxWidth: width,
                        }}
                        mobileBreakpoint={0}
                        autoplay={7000}
                        hideArrow={true}
                        loop
                        rows={1}
                        cols={1}>
                        {reviews.map(review => (
                            <Carousel.Item key={review.name}>
                                <div className="flex flex-col items-center">
                                    <p className="text-copy font-thin text-center text-lg flex-wrap">&ldquo;{review.review}&rdquo;</p>
                                    <span className="text-gold text-xl mt-4">- {review.name} -</span>
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>

            </div>

            <ResultCarousel/>

            <div className="mt-8 flex justify-center items-center">
                <Button title="Klik voor meer resultaten" link={configData.instagramUrl}/>
            </div>
        </div>
    );
}

export default React.memo(Results);
