import React from 'react';
import bodyFont from "@/components/bodyFont";
import SectionTitle from "@/components/sectionTitle";

function Introduction() {
    return (
        <div className="bg-primary flex flex-col justify-center items-center py-20 text-gold px-5">
            <SectionTitle>Hey</SectionTitle>
            <p className="text-lg md:text-xl font-thin max-w-lg text-center">
                Ben jij toe aan een next level coupe?
                <br/>
                Een plek waar het even om jou draait?
                <br/>
                Even onthaasten, even geen stress.
                <br/>
                Plof dan neer op mijn bordeauxrode zetel en relax.
                <br/>
                <br/>
                Knippen, kleuren, dry cutting of krul,
                samen creëren we iets moois dat jou geweldig staat.
                <br/>
                Want je bent pas zelfverzekerd als je naar de juiste kapper gaat.
                <br/>
                <br/>
                <span className="font-bold">
                ~ Kus Lisa
                </span>
            </p>
        </div>
    );
}

export default React.memo(Introduction);
