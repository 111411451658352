import React from 'react';
import configData from "@/components/utils/configData";

function Footer() {
    return (
        <div className="bg-black py-20 items-center">

            <div className={`grid md:grid-cols-2 max-w-2xl mx-auto text-copy font-thin leading-relaxed gap-20 px-5`}>
                <div className="flex flex-col md:items-end">
                    <h6 className="font-medium text-xl mb-4">Openingstijden:</h6>
                    <div className="flex flex-col w-full sm:w-2/3 md:w-full">
                        <div className="flex flex-row justify-between">
                            <span className="mr-4">Dinsdag</span>
                            <span>08.30 - 18.00</span>
                        </div>
                        <div className="flex flex-row justify-between">
                            <span className="mr-4">Donderdag</span>
                            <span>08.30 - 18.00</span>
                        </div>
                        <div className="flex flex-row justify-between">
                            <span className="mr-4">Vrijdag</span>
                            <span>08.30 - 18.00</span>
                        </div>
                        <div className="flex flex-row justify-between">
                            <span className="mr-4">Zaterdag</span>
                            <span>feestkapsels</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-end">
                    <h5 className="font-medium text-xl mb-6">Adres</h5>
                    <div className="flex flex-col">
                        <a href="https://goo.gl/maps/Bdu4qrZryYsLCn8R8">
                            Oudelieshoutseweg 6a
                            <br/>
                            5492 HT te Nijnsel
                        </a>
                        <a href="tel:+31623412225">06 2341 2225</a>
                        <a href="mailto:+31623412225">hairstylistelisa@hotmail.com</a>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center mt-10 gap-x-8">
                <a className="text-copy" aria-label="Instagram" target="_blank" href={configData.instagramUrl}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-copy w-10 h-10" viewBox="0 0 24 24" fill="currentColor">
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M22.9 7.00009C22.9 3.74409 20.256 1.1001 17 1.1001H7C3.744 1.1001 1.1 3.74409 1.1 7.00009V17.0001C1.1 20.2561 3.744 22.9001 7 22.9001H17C20.256 22.9001 22.9 20.2561 22.9 17.0001V7.00009ZM21.1 7.00009V17.0001C21.1 19.2631 19.263 21.1001 17 21.1001H7C4.737 21.1001 2.9 19.2631 2.9 17.0001V7.00009C2.9 4.73709 4.737 2.90009 7 2.90009H17C19.263 2.90009 21.1 4.73709 21.1 7.00009ZM16.89 11.2381C16.575 9.1091 14.891 7.42509 12.762 7.11009C12.524 7.07409 12.284 7.0571 12.043 7.0571C9.355 7.0571 7.143 9.26909 7.143 11.9571C7.143 14.6451 9.355 16.8571 12.043 16.8571C14.731 16.8571 16.943 14.6451 16.943 11.9571C16.943 11.7161 16.926 11.4761 16.89 11.2381ZM15.11 11.5021C15.132 11.6531 15.143 11.8051 15.143 11.9571C15.143 13.6571 13.744 15.0571 12.043 15.0571C10.343 15.0571 8.943 13.6571 8.943 11.9571C8.943 10.2561 10.343 8.85709 12.043 8.85709C12.195 8.85709 12.347 8.86809 12.498 8.89009C13.845 9.09009 14.91 10.1551 15.11 11.5021ZM17.5 5.6001C17.997 5.6001 18.4 6.00309 18.4 6.50009C18.4 6.99709 17.997 7.40009 17.5 7.40009C17.003 7.40009 16.6 6.99709 16.6 6.50009C16.6 6.00309 17.003 5.6001 17.5 5.6001Z"
                                  strokeWidth="0.2"/>
                        </g>
                    </svg>
                </a>
                <a aria-label="Facebook" target="_blank" href={configData.facebookUrl}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-copy w-10 h-10" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                        <g>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9.1 9.1001V7.0001C9.1 3.7631 11.763 1.1001 15 1.1001H18C18.497 1.1001 18.9 1.5031 18.9 2.0001V6.0001C18.9 6.4971 18.497 6.9001 18 6.9001H15C14.945 6.9001 14.9 6.9451 14.9 7.0001V9.1001H18C18.277 9.1001 18.539 9.2281 18.709 9.4461C18.88 9.6651 18.94 9.9491 18.873 10.2181L17.873 14.2181C17.773 14.6191 17.413 14.9001 17 14.9001H14.9V22.0001C14.9 22.4971 14.497 22.9001 14 22.9001H10C9.503 22.9001 9.1 22.4971 9.1 22.0001V14.9001H7C6.503 14.9001 6.1 14.4971 6.1 14.0001V10.0001C6.1 9.5031 6.503 9.1001 7 9.1001H9.1ZM17.1 2.9001H15C12.751 2.9001 10.9 4.7511 10.9 7.0001V10.0001C10.9 10.4971 10.497 10.9001 10 10.9001H7.9V13.1001H10C10.497 13.1001 10.9 13.5031 10.9 14.0001V21.1001H13.1V14.0001C13.1 13.5031 13.503 13.1001 14 13.1001H16.297L16.847 10.9001H14C13.503 10.9001 13.1 10.4971 13.1 10.0001V7.0001C13.1 5.9581 13.958 5.1001 15 5.1001H17.1V2.9001Z"
                                  strokeWidth="0.2"/>
                        </g>
                    </svg>
                </a>
            </div>
        </div>
    );
}

export default React.memo(Footer);
