import React, {useCallback, useLayoutEffect, useRef, useState} from 'react';
import jumbotronImage from '@/assets/img/jumbotron.jpg'
import {motion, useInView} from "framer-motion";
import animationData from '@/assets/lisa_animation.json'
import Lottie from "lottie-react";

function Jumbotron() {
    const ref = useRef();
    const stickyRef = useRef();
    const lottieRef = useRef();
    const lottieRefSmall = useRef();
    const [height, setHeight] = useState(200);
    const inView = useInView(ref);

    useLayoutEffect(() => {
        setHeight(stickyRef.current.offsetHeight);
    }, [stickyRef.current]);

    const onLoopComplete = useCallback((ref) => {
        ref.current.pause();
        setTimeout(() => {
            ref.current.goToAndPlay(0)
        }, 8000)

    }, [])

    return (
        <>
            <motion.div
                ref={stickyRef}
                layoutScroll
                layoutRoot
                style={{
                    zIndex: 1000,
                    left: 0,
                    right: 0,
                    top: 0,
                    transform: inView ? "translateY(-" + height + height + "px)" : 'none',
                    position: 'sticky',
                }}>
                <motion.div
                    layout
                    className="flex items-center justify-center backdrop-blur-sm bg-gray-800/30">
                    <Lottie rendererSettings={{
                        progressiveLoad: true,
                    }} lottieRef={lottieRefSmall} loop={false} animationData={animationData} onComplete={() => onLoopComplete(lottieRefSmall)} className="object-contain w-[100px]"/>
                </motion.div>
            </motion.div>

            <div style={{
                backgroundImage: `url(${jumbotronImage.src})`,
                backgroundRepeat: 'no-repeat',
                marginTop: (-height) + 'px',
            }} className="h-[500px] md:h-screen justify-center bg-center bg-cover items-center flex">
                <div ref={ref} className="w-full md:w-1/2">
                    <Lottie rendererSettings={{
                        progressiveLoad: true,
                    }} lottieRef={lottieRef} loop={false} onComplete={() => onLoopComplete(lottieRef)} animationData={animationData} className="w-full object-contain"/>
                </div>

            </div>
        </>
    );
}

export default React.memo(Jumbotron);
